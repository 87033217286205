import React, { useEffect, useState } from 'react';
import SideBar from '../layouts/SideBar';
import DataTable from 'react-data-table-component';
import customStyles from '../Utils/CustomStyles';
import useFetch from "../ApiClient/GetApi";
import { PostApi } from "../ApiClient/PostApi";
import { getAllSubscriptionPlans, addPlan, deletePlanRoute } from '../Utils/routes'; // Assuming addPlan is the API endpoint for adding new plans
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal, Button, Form } from "react-bootstrap"; // Import Modal and Form components from react-bootstrap
import { success_toaster, error_toaser, error_toaster } from "../Utils/Toaster";
import Loader from "../Utils/Loader"

export default function Plan() {
    // Fetch the API data
    const { apiData,reFetch } = useFetch(getAllSubscriptionPlans);

    // State for formatted plans
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    // State for managing modal visibility
    const [showModal, setShowModal] = useState(false);

    // State for form inputs
    const [planData, setPlanData] = useState({
        name: '',
        interval: 'month', // Default value
        price: ''
    });

    // Format API data into the structure needed for the table
    useEffect(() => {
        if (apiData && apiData.data && apiData.data.plans) {
            const formattedPlans = apiData.data.plans.map((plan, index) => ({
                srNo: index + 1,
                priceId: plan.id,
                productId: plan.productId,
                productName: plan.productName,
                amount: (plan.amount / 100).toFixed(2), // Convert amount from cents to dollars
                currency: plan.currency.toUpperCase(),
                interval: plan.interval,
                productDescription: plan.productDescription,
                action: (
                    <div className="edit-delete-action d-flex gap-2">
                        <div className="cursor-pointer">
                            <FaEdit size={25} />
                        </div>
                        <div>
                            <RiDeleteBin6Line onClick={()=>deletePlan(plan.productId,plan.id)} size={25} />
                        </div>
                    </div>
                ),
            }));
            setPlans(formattedPlans);
        }
    }, [apiData]);

    // Handle modal show
    const handleShowModal = () => setShowModal(true);

    // Handle modal close
    const handleCloseModal = () => setShowModal(false);

    // Handle form field change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPlanData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission to add a new plan
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Make the API call to add a new plan
            const response = await PostApi(addPlan, { planData }); // Assuming the API function is named `addPlan`

            if (response.data.status === '1') {
                reFetch();
                setLoading(false);
                success_toaster(response.data.message);
                setShowModal(false);
                // Optionally, re-fetch the plans or update the state to include the new plan

            } else {
                error_toaster(response.data.message);
                setLoading(false)
            }
        } catch (error) {
            error_toaster(error.message);
            setLoading(false)
        }
    };
    const deletePlan = async (productId,priceId) => {
       
        setLoading(true);
        try {
            // Make the API call to add a new plan
            const response = await PostApi(deletePlanRoute, { productId,priceId }); // Assuming the API function is named `addPlan`

            if (response.data.status === '1') {
                reFetch();
                setLoading(false);
                success_toaster(response.data.message);
              

            } else {
                error_toaster(response.data.message);
                setLoading(false)
            }
        } catch (error) {
            error_toaster(error.message);
            setLoading(false)
        }
    };

    // Define the columns for DataTable
    const columns = [
        {
            name: "Sr #",
            selector: (row) => row.srNo,
            wrap: true,
            width: "100px",
        },
        {
            name: "Product Name",
            selector: (row) => row.productName,
            wrap: true,
            width: "200px",
        },
        {
            name: "Amount",
            selector: (row) => `${row.currency} ${row.amount}`,
            wrap: true,
        },
        {
            name: "Interval",
            selector: (row) => row.interval,
            wrap: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            wrap: true,
        },
    ];

    return (
        <div>
            <SideBar />
            {
                loading ? <Loader/>:<div>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="page-header">
                                <div className="page-title">
                                    <h2 style={{ textAlign: "start" }}>All Plans</h2>
                                    <h6>View all plans here</h6>
                                </div>
                                <div className="page-btn">
                                    <Button
                                        variant="primary"
                                        onClick={handleShowModal}
                                    >
                                        <i data-feather="plus-circle" className="me-2"></i>New Plan
                                    </Button>
                                </div>
                            </div>

                            <div className="card table-list-card">
                                <div className="card-body">
                                    <div className="table-responsive product-list">
                                        <DataTable
                                            theme="myTheme"
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={plans}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="planName">
                                <Form.Label>Plan Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter plan name"
                                    name="name"
                                    value={planData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="planInterval">
                                <Form.Label>Interval</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="interval"
                                    value={planData.interval}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="month">Month</option>
                                    <option value="week">Week</option>
                                    <option value="year">Year</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="planPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter price"
                                    name="price"
                                    value={planData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Close
                                </Button>
                                <Button variant="primary" type="submit">
                                    Add Plan
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            }
        </div>
    );
}
