import React, { useEffect, useState } from 'react';
import SideBar from '../layouts/SideBar';
import DataTable from 'react-data-table-component';
import customStyles from '../Utils/CustomStyles';
import useFetch from "../ApiClient/GetApi";
import { getAllUsers } from '../Utils/routes'; // Assuming getAllUsers fetches this data
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { formatDate } from '../Utils/DateFormat';

export default function Users() {

  // Fetch the API data
  const { apiData } = useFetch(getAllUsers);

  // State for formatted users
  const [users, setUsers] = useState([]);

  // Format API data into the structure needed for the table
  useEffect(() => {
    if (apiData && apiData.data && apiData.data.users) {
      const formattedUsers = apiData.data.users.map((user, index) => ({
        srNo: index + 1, // Serial number for each user
        id: user.id,
        name: user.name,
        email: user.email,
        status: user.status ? 'Active' : 'Inactive', // Display active/inactive status
        createdAt: formatDate(user.createdAt), // Format the date
        action: <div class="edit-delete-action d-flex gap-2">
                    <div className='cursor-pointer'><FaEdit size={25} /></div>
                    <div><RiDeleteBin6Line size={25} /></div>
                    </div>, // Add a button or other action as needed
      }));
      setUsers(formattedUsers);
    }
  }, [apiData]);

  // Define the columns for DataTable
  const columns = [
   
    {
      name: "User ID",
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
    },
  ];

  return (
    <div>
      <SideBar />
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="page-header">
              <div className="page-title">
                <h4>All Users</h4>
                <h6>View all users here</h6>
              </div>
              <div className="page-btn">
                <a href="add-user.html" className="btn btn-added" data-bs-toggle="modal" data-bs-target="#addUserModal">
                  <i data-feather="plus-circle" className="me-2"></i>New User
                </a>
              </div>
            </div>

            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-responsive product-list">
                  <DataTable
                    theme="myTheme"
                    customStyles={customStyles}
                    columns={columns}
                    data={users} // Populate with formatted users data
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
