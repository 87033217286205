// export const BASE_URL = "http://localhost:8000/admin"
// export const IMAGE_URL = "http://localhost:8000"
export const BASE_URL = "https://fyp.trimworldwide.com/admin/"
export const IMAGE_URL = "https://fyp.trimworldwide.com/"
// export const BASE_URL = "https://backend.resultsinafrica.com/frontsite/"
// export const IMAGE_URL = "https://backend.resultsinafrica.com/"

// export const SITE_KEY = "6Lc-KIAqAAAAAFTHfCMBhi1_-wt6IKPB3SYJrwuY"


// y1