
import { ToastContainer } from 'react-toastify';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './components/Login';
import { homeRoute, plansRoute, signinRoute, usersRoutes } from './Utils/routes';
import Home from './components/Home';
import Plan from './components/Plan';
import Users from './components/Users';

// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
    <ToastContainer />
   <Router>
     <div>
       {/* Route Definitions */}
       <Routes>
       
         <Route path={signinRoute} element={<Login />} />
         <Route path={homeRoute} element={<Home />} />
         <Route path={plansRoute} element={<Plan />} />
         <Route path={usersRoutes} element={<Users />} />
        
        
       </Routes>
     </div>
   </Router>
 </div>
  );
}

export default App;
