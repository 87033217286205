export const homeRoute = "/";
export const signinRoute = "/sign-in";
export const registerRoute = "/register";
export const plansRoute = "/all-plans";
export const paymentSuccessRoute = "/payment-success";
export const createCasesRoute = "/create-case";
export const usersRoutes = "/all-users";


// apis routes

export const login = "/login"
export const getAllSubscriptionPlans = "/getAllSubscriptionPlans"
export const getAllUsers = "/getAllUsers"
export const addPlan = "/addPlan"
export const deletePlanRoute = "/deletePlan"