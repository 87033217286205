import { createTheme } from "react-data-table-component";

const customStyles = {
    rows: {
        style: {
            minHeight: "50px",
            margin: "4px 0",
            // borderRadius: "8px",
            overflow: "hidden",       
        },
    },
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: "600",
            color: "rgba(0, 0, 0, 0.6)",
            
            background:"#b6bbc0"
        },
    },
    cells: {
        style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "black",
            background: "#fafbfe", 

        },
    },
};

const theme = createTheme("myTheme", {
    background: {
        default: "transparent",
    },
    divider: {
        default: "transparent",
    },
});

export default customStyles;
export { theme };